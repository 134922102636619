





























import { apiGETSingle, apiModify } from '@/api';
import { API } from '@/types';
import { Vue, Component } from 'vue-property-decorator';
import { Socket } from 'vue-socket.io-extended';
import Field from './components/Field.vue';
import SponsorTable from '@/components/Admin/SponsorTable.vue';
import { rootModule } from '@/store';

@Component({
  components: {
    Field,
    SponsorTable,
  },
})
export default class extends Vue {
  companyData: API.CompanyData.GET | null = null;
  sponsorIds = [];

  async created(): Promise<void> {
    Vue.set(this, 'companyData', (await apiGETSingle('companyData')).data);
    Vue.set(this, 'sponsorIds', this.companyData?.sponsorIds.slice(0) || []);
  }

  modifyPost(data: API.CompanyData.GET | null): void {
    Vue.set(this, 'companyData', { ...this.companyData, ...data });
    Vue.set(this, 'sponsorIds', data?.sponsorIds.slice(0) || []);
  }

  @Socket('companyDataModified')
  socketCompanyDataModified(newVal: API.CompanyData.GET | null): void {
    Vue.set(this, 'companyData', { ...this.companyData, ...newVal });
    Vue.set(this, 'sponsorIds', newVal?.sponsorIds.slice(0) || []);
  }

  async save(input: number[]): Promise<void> {
    try {
      const data = await apiModify('companyData', null, { sponsorIds: input }, 'PUT');
      this.$emit('post', data.data);
      rootModule.setSaved(true);
    } catch (err) {
      // TODO: Something with this error.
    }
  }
}
