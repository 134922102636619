



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { apiModify } from '@/api';
import { rootModule } from '@/store';

@Component
export default class extends Vue {
  @Prop(Object) readonly data!: API.CompanyData.GET | null;
  @Prop({ type: String, required: true }) readonly header!: string;
  @Prop({ type: String, required: true }) readonly paramKey!: keyof API.CompanyData.GET;
  editing = false;
  edited: string | null = null;

  edit(): void {
    const data = this.data?.[this.paramKey];
    if (data === null || typeof data === 'string') {
      this.edited = data ?? null;
      this.editing = true;
    }
  }

  async save(): Promise<void> {
    try {
      const data = await apiModify('companyData', null, { [this.paramKey]: this.edited }, 'PUT');
      this.$emit('post', data.data);
      rootModule.setSaved(true);
    } catch (err) {
      // TODO: Something with this error.
    }
    this.editing = false;
  }
}
