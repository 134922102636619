





















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import Tooltip from '@/components/Admin/Tooltip.vue';
import { API } from '@/types';
import { apiGETMulti } from '@/api';

@Component({
  components: {
    Tooltip,
  },
})
export default class extends Vue {
  @Ref('EditDialog') editDialog!: unknown;
  @Prop({ type: Array, required: true }) readonly value!: number[];
  sponsors: API.Sponsors.GET[] = [];
  loading = false;
  addSponsorId: number | null = null;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.sponsors = (await apiGETMulti('sponsors', {}, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }

  getSponsorName(id: number): string {
    return this.sponsors.find((s) => s.id === id)?.name || '?';
  }

  addSponsor(): void {
    if (this.addSponsorId !== null) {
      const newArr = this.value.slice(0);
      newArr.push(this.addSponsorId);
      this.$emit('input', newArr);
      this.addSponsorId = null;

      // Programatically closes v-edit-dialog in the hardest way possible.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.editDialog as any).isActive = false;
    }
  }

  deleteSponsor(index: number): void {
    const newArr = this.value.slice(0);
    newArr.splice(index, 1);
    this.$emit('input', newArr);
  }

  get sponsorsAddable(): API.Sponsors.GET[] {
    return this.sponsors.filter((s) => !this.value.includes(s.id));
  }
}
